.thongkenhanh{
    margin-bottom: 30px;
    border-radius: 10px;
    background-color: white;
    height: 100%;
    padding-bottom: 40px;
}

#name{
    border-bottom: 1px solid black;
    padding: 10px;
    font-weight: bold;
}

#giatri{
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    width: 100%;
    border-radius: 10px;
    width: 100%;
    height: 100%;
}

.moithem{
    border-radius: 10px;
    background-color: white;
    padding-bottom: 40px;
    width: 49%;
    padding: 20px 0;
    margin: 0
}

.moithem span{
    font-weight: bold;
    padding-left: 10px;
}

.quahan{
    border-radius: 10px;
    background-color: white;
    padding-bottom: 40px;
    width: 49%;
    padding: 20px;
}

.quahan span{
    font-weight: bold;
    padding-left: 10px;
}