/* Header Custom Style */
.page-header {
  background: white;
  padding: 16px 24px;
  border-bottom: 1px solid #e8e8e8;
}

.ant-page-header-heading-title {
  font-size: 20px;
  font-weight: bold;
  margin-top: -20px;
}

.ant-page-header-footer {
  padding-bottom: 15px;
  margin-top: -20px;
  font-size: 14px;
  color: grey;
}

.ant-btn-primary {
  font-size: 15px;
  padding: 0 10px;
  color: white;
  font-weight: normal;
  background-color: #0056d2;
  border-color: #0056d2;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #003da5;
  border-color: #003da5;
}

/* Tabs Custom Style */
.ant-tabs-nav {
  margin-bottom: 1px !important;
  padding-left: 16px;
}

.ant-tabs-tab {
  padding: 8px 16px;
  font-size: 16px;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ff8c40 !important;
}

.ant-tabs-ink-bar {
  background: #ff8c40;
}
